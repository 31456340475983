import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CacheRequestService {

  private _listAPIsCaching: string[] = [
    environment.baseUrl + environment.API.ContactChannels.get,
  ];

  private _listAPIsPending: string[] = [
  ];

  constructor() { }

  isCacheRequestAPI(url: string) : boolean {
    return this._listAPIsCaching.includes(url) ? true : false;
  }

  isAPIPending(url: string) : boolean {
    return this._listAPIsPending.includes(url) ? true : false;
  }
}

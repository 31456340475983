// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.
import { commonEnv } from "./environment.common";

const env: Partial<typeof commonEnv> =  {
  production: false,
   //baseUrl: 'https://localhost:44392/api/'
  // baseUrl: 'https://758b55ab-fce8-49c1-b115-fd10dcb3f850.mock.pstmn.io/api/',
};
// Export all settings of common replaced by dev options
export const environment = Object.assign(commonEnv, env);
/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.

import { AfterContentChecked, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { NgbModalConfig, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { PrimeNGConfig } from 'primeng/api';
import { environment } from 'src/environments/environment';
import { LoaderService } from './core/services/loader/loader.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html'
})
export class AppComponent implements OnInit, AfterContentChecked {
  title = 'Feaah-WebPortal';

  isRefreshing: boolean = false;
  timeSession: string = '10';
  private modalRef!: NgbModalRef;

  constructor(config: NgbModalConfig,
    private translateService: TranslateService,
    public loaderService: LoaderService,
    private cdRef: ChangeDetectorRef,
    private _primeNGConfig: PrimeNGConfig
  ) {
    config.backdrop = 'static';
    config.keyboard = false;
  }

  ngOnInit(): void {
    /// Set default language for app if not found in local storage.
    this._checkLang();

    this.loaderService.show();
  }

  ngAfterContentChecked() {
    /// Refresh components once change laugauge from web to complete language translate components
    this.cdRef.detectChanges();
  }

  /**
   * Check language and set a default language if not found in local storage
   */
   private _checkLang(): void {
    const localStorageLang = localStorage.getItem(environment.commonproperty.localStorage.language) || 'en';
    
    this._setDocumentDirection(localStorageLang);
    this.translateService.use(localStorageLang);

    this.translateService.onLangChange.subscribe((lang: LangChangeEvent) => {
      this._setDocumentDirection(lang.lang);

      const t = lang.translations;

      // this._primeNGConfig.setTranslation({
      //   monthNames: t.GENERAL.CALANDER.MONTHS.split('|'),
      //   monthNamesShort: t.GENERAL.CALANDER.MONTHS_NAMES_SHORT.split('|'),
      //   dayNamesMin:t.GENERAL.CALANDER.DAYS_NAMES_SHORT.split('|'),
      //   today: t.GENERAL.CALANDER.TODAY,
      //   clear: t.GENERAL.CALANDER.CLEAR
      // });
    });
  }

  /**
   * Change class from html needed to be completed design
   * @param lang Language property
   */
  private _setDocumentDirection(lang: string): void {
    this.translateService.reloadLang(lang);
    const direction = lang == 'ar' ? 'rtl' : 'ltr';
    const htmlTag = document.getElementsByTagName('html')[0];
    htmlTag.setAttribute('dir', direction);
  }
}

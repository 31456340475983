import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class LoaderService {

  private isLoading$$ = new BehaviorSubject<boolean>(false);
  isLoader$ = this.isLoading$$.asObservable();

  get loaderStatus(): Observable<boolean> {
    return this.isLoader$;
  }

  show() {
    this.isLoading$$.next(true);
  }

  hide() {
    this.isLoading$$.next(false);
  }
}

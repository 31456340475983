import { ErrorHandler, NgModule, VERSION } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule } from '@angular/common/http';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { MessageService } from 'primeng/api';
import { ToastModule } from 'primeng/toast';
import { NgHttpCachingConfig, NgHttpCachingEntry, NgHttpCachingMemoryStorage, NgHttpCachingModule, NgHttpCachingStrategy } from 'ng-http-caching';
import { NoopAnimationsModule } from '@angular/platform-browser/animations';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { AppComponent } from './app.component';
import { CustomPageTitleStrategy } from './shared/strategy/customPageTitleStrategy';
import { TitleStrategy } from '@angular/router';
import { ErrorHandlerService } from './core/services/error-handler/error-handler.service';
import { TokenInterceptor } from './core/interceptors/token.interceptor';
import { CacheHttpTranslationLoader } from './shared/servies/cache-translation-loader/cache-http-translation-loader.service';
import { AppRoutingModule } from './app-routing.module';
import { SweetAlert2Module } from '@sweetalert2/ngx-sweetalert2';

const ngHttpCachingConfig: NgHttpCachingConfig = {
  store: new NgHttpCachingMemoryStorage(),
  version: VERSION.major,
  allowedMethod: ['GET', 'POST'],
  cacheStrategy: NgHttpCachingStrategy.DISALLOW_ALL,
  lifetime: 1000 * 60 * 10, // cache expire after 10 minutes
  isValid: (entry: NgHttpCachingEntry): boolean | undefined => {
    // In this example only response with status code 200 can be stored into the cache
    return entry.response.status === 200;
  },
};

@NgModule({
  declarations: [ AppComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    NoopAnimationsModule,
    HttpClientModule,
    ToastModule,
    TranslateModule.forRoot({
      defaultLanguage: 'en',
      loader: {
        provide: TranslateLoader,
        useClass: CacheHttpTranslationLoader,
        deps: [HttpClient],
      },
    }),
    NgHttpCachingModule.forRoot(ngHttpCachingConfig),
    SweetAlert2Module.forRoot(),
  ],
  providers: [
    MessageService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true,
    },
    { provide: TitleStrategy, useClass: CustomPageTitleStrategy },
    {provide: ErrorHandler, useClass: ErrorHandlerService}
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

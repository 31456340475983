import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class TokenStorageService {

  constructor() { }

  saveAuthTokens(response: any) {
    localStorage.setItem(this.getTokenKey(), response.data?.token);
    localStorage.setItem(this.getTokenExpiryKey(), response.data?.expireOn);
    // sessionStorage.setItem(this.getRefreshTokenKey(), response.data.RefreshToken);
    // sessionStorage.setItem(this.getRefreshTokenExpiryKey(), response.data.RefreshTokenExpireUTC);
  }

  clientLogout() {
    localStorage.removeItem(this.getTokenKey());
    localStorage.removeItem(this.getTokenExpiryKey());
    // sessionStorage.removeItem(this.getRefreshTokenKey());
    // sessionStorage.removeItem(this.getRefreshTokenExpiryKey());
  }

  isLoggidIn() {
    return localStorage.getItem(this.getTokenKey()) ? true : false;
  }

  getToken(): string {
    return localStorage.getItem(environment.commonproperty.localStorage.token) ?
        'Bearer ' + localStorage.getItem(environment.commonproperty.localStorage.token) : '';
  }

  getExpireToken(): string {
    return localStorage.getItem(this.getTokenExpiryKey()) ?? '';
  }

  getRefreshToken(): string {
    return localStorage.getItem(this.getRefreshTokenKey()) ?? '';
  }

  getRefreshTokenExpiry(): string {
    return localStorage.getItem(this.getRefreshTokenExpiryKey()) ?? '';
  }

  getTokenKey(): string {
    return "token";
  }
  getTokenExpiryKey(): string {
    return "token_expire";
  }

  getRefreshTokenKey(): string {
    return "refresh_token";
  }
  getRefreshTokenExpiryKey(): string {
    return "refresh_token_expire";
  }
}

import { HttpClient } from '@angular/common/http';
import { TranslateLoader } from '@ngx-translate/core';
import { Observable, shareReplay } from 'rxjs';
/**
 * Cached http translation loader to load translater from server at once
 */
export class CacheHttpTranslationLoader implements TranslateLoader {

  private cache$: Observable<Object> | null = null;
  private cachedLang: string | null = null;

  constructor(private http: HttpClient, public prefix: string = "/assets/i18n/", public suffix: string = ".json") {}

  /**
   * Gets the translations from the server
   */
  public getTranslation(lang: string): Observable<Object> {
    if (!this.cache$ || this.cachedLang !== lang) {
      this.cache$ = this.http.get(`${this.prefix}${lang}${this.suffix}`).pipe(shareReplay(1));
      this.cachedLang = lang;
    }
    return this.cache$;
  }
}

import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { asapScheduler, catchError, finalize, Observable, of, scheduled, shareReplay, switchMap, tap, EMPTY, BehaviorSubject, filter, take } from 'rxjs';
import { LoaderService } from "../services/loader/loader.service";
import { NgHttpCachingHeaders, NgHttpCachingService } from 'ng-http-caching';
import { CacheRequestService } from 'src/app/shared/servies/cache-request/cache-request.service';
import { LocalStorageService } from 'src/app/shared/servies/local-storage/local-storage.service';
import { environment } from 'src/environments/environment';
import { LogoutService } from 'src/app/shared/servies/logout/logout.service';
import { TokenStorageService } from 'src/app/shared/servies/token-storage/token-storage.service';

/** const wait = (t: number) => new Promise((r) => setTimeout(r, t)); */

/**
 * Fix for https://github.com/ReactiveX/rxjs/issues/7241
 */
function* _of<T>(value: T): Generator<T> {
  yield value;
}

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  private token!: any;

  private language: string = 'en';

  private transactionID: string = Math.floor(Math.random() * 5000).toString();

  private requests: HttpRequest<any>[] = [];

  private isRefreshing = false;

  private isPending = false;

  private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);

  constructor(
    private loaderService: LoaderService,
    private _cacheRequestService: CacheRequestService,
    private readonly cacheService: NgHttpCachingService,
    private _localStoarge: LocalStorageService,
    private _tokenStorage: TokenStorageService,
    private logoutService: LogoutService
  ) {
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // set api request caching true if it's exists
    request = this.addCacheRequest(request);

    // run garbage collector
    this.cacheService.runGc();

    // Don't cache if it's not cacheable
    if (!this.cacheService.isCacheable(request)) {
      return this.sendRequest(request, next);
    }

    // Checked if there is pending response for this request
    const cachedObservable: Observable<HttpEvent<any>> | undefined = this.cacheService.getFromQueue(request);
    if (cachedObservable) {
      return cachedObservable;
    }

    // Checked if there is cached response for this request
    const cachedResponse: HttpResponse<any> | undefined = this.cacheService.getFromCache(request);
    if (cachedResponse) {
      return scheduled(_of(cachedResponse.clone()), asapScheduler);
    }

    // If the request of going through for first time
    // then let the request proceed and cache the response
    const shared = this.sendRequest(request, next).pipe(
      tap(event => {
        if (event instanceof HttpResponse && event.body?.isSuccess) {
          this.cacheService.addToCache(request, event.clone());
        }
      }),
      finalize(() => {
        // delete pending request
        this.cacheService.deleteFromQueue(request);
      }),
      shareReplay()
    );

    // add pending request to queue for cache parallell request
    this.cacheService.addToQueue(request, shared);

    return shared;
  }

  /**
   *
   * @param request request api with body and headers
   * @param next
   * @returns
   */
  private sendRequest(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // set token from local stoarge that can be using in request apis
    this._setCurrentToken();

    // set current Lang to using in requests apis
    this._setCurrentLang();

    // set random transaction id with any apis request
    this._setTransactionId();

    // set request in memory to return it if needed and remove it once return response
    this.requests.push(request);

    // check token if exist or not to be requesting with token or not
    if (this.token) {
      return next.handle(
        this.addHeadersToRequest(request)
      ).pipe(
        catchError((error) => {
          if (error instanceof HttpErrorResponse && error.status === 401) {
            return this.handle401Error(request, next);
          } else if (error.url.includes(environment.API.Auth.refreshToken)) {
            this.isRefreshing = false;
            this.logoutService.logOut();
            return EMPTY;
          } else {
            throw error;
          }
        }),
        finalize(() => this.removeRequest(request))
      );
    } else {
      return next.handle(
        this.addHeadersToRequest(request)).pipe(
        finalize(() => this.removeRequest(request)),
      );
    }
  }

  /**
   * @information get request with headers request default
   * @param request request apis
   * @returns
   */
  private addHeadersToRequest(request: HttpRequest<any>, token: string = ''): HttpRequest<any> {
    // set headers requests apis default if it's api request only
    if (request.url.includes('api')) {
      this.loaderService.show();

      // set headers default
      let headers = request.headers
        .append('Accept', 'application/json')
        .append('Access-Control-Allow-Headers', 'Content-Type');

      if (token) {
        this.token = 'Bearer ' + token;
      }

      if (!request.url.includes('FileUpload')) { headers = headers.append('Content-Type', 'application/json'); }

      if (this.token) { headers = headers.append('Authorization', this.token); }

      if (this.language) { headers = headers.append('Language', this.language); }

      if (this.transactionID) { headers = headers.append('TransactionID', this.transactionID); }

      return request.clone({
        headers: headers
      });
    }
    return request;
  }

  /**
   * @information add cache request api by header request and return request api if it's needed
   * @param request request apis
   * @returns request api with header caching if it's exists
   */
  private addCacheRequest(request: HttpRequest<any>): HttpRequest<any> {
    const url = request.url;
    if (url.includes('api') && this._cacheRequestService.isCacheRequestAPI(url)) {
      let headers = request.headers.append(NgHttpCachingHeaders.ALLOW_CACHE, '1');

      return request.clone({
        headers: headers
      });
    }
    return request;
  }

  /**
   * information that remove last request
   * @param req requests param
   */
  private removeRequest(req: HttpRequest<any>) {
    const index = this.requests.indexOf(req);
    this.requests.splice(index, 1);
    if (this.requests.length <= 0) {
      this.loaderService.hide();
    }
  }

  /**
   * @information handle 401 error to get refresh token
   * @param request requests apis
   * @param next
   * @returns
   */
  private handle401Error(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    if (!this.isRefreshing) {
      this.isRefreshing = true;

      this.refreshTokenSubject.next(null);

      return this.sendRequestAgain(request, next);
    }
    else {
      return this.sendRequestAgain(request, next);
    }
  }

  private pendingRequest(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (!this.isPending) {
      this.isPending = true;

      if (request.url.includes('##')) {
        request = request.clone({
          url: request.url.replace('##', '')
        });
      }

      return next.handle(
        this.addHeadersToRequest(request)
      ).pipe(
        catchError((error) => {
          this.isPending = false;
          if (error instanceof HttpErrorResponse && error.status === 401) {
            return this.handle401Error(request, next);
          } else {
            return EMPTY;
          }
        }),
        finalize(() => { this.isPending = false; this.removeRequest(request) })
      );
    }

    if (!request.url.includes('##')) {
      request = request.clone({
        url: '##' + request.url
      });
    }

    return next.handle(
      this.addHeadersToRequest(request)
    ).pipe(
      catchError((error) => {
        if (error instanceof HttpErrorResponse && error.status === 401) {
          return this.handle401Error(request, next);
        } else if (error.status == 404) {
          return this.pendingRequest(request, next);
        } else {
          return EMPTY;
        }
      })
    );
  }

  /**
   * @help to get current token and used it from local stoarge
   */
  private _setCurrentToken(): void {
    this.token = this._tokenStorage.getToken();
  }

  /**
   * @help to get current language to used it in request headers from local stoarge
   */
  private _setCurrentLang(): void {
    const lang = this._localStoarge.getLang();
    if (lang)
      this.language = lang == 'en' ? 'en' : 'ar';
    else
      this.language = 'ar';
  }

  /**
   * @help get transaction id as random number to used it in headers as per request
   */
  private _setTransactionId(): void {
    this.transactionID = Math.floor(Math.random() * 5000).toString();
  }

  /**
   * Try to send request again with new token
   * @param request last request
   * @param next next request
   * @returns return response
   */
  private sendRequestAgain(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return this.refreshTokenSubject.pipe(
      filter(token => token !== null),
      take(1),
      switchMap((token) => next.handle(this.addHeadersToRequest(request, token)))
    );
  }

  /**
   * @Helper for reference
  */
  private syncDelay(milliseconds: number): void {
    const start = new Date().getTime();
    let end = 0;

    while ((end - start) < milliseconds) {
      end = new Date().getTime();
    }
  }
}

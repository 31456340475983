import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { iUserModel } from '../../Interfaces/iUserData';

@Injectable({
  providedIn: 'root'
})
export class LocalStorageService {

  constructor() { }

  getLang(): string {
    return localStorage.getItem(environment.commonproperty.localStorage.language) || 'ar';
  }

  getUserId(): number {
    //let userData = JSON.parse(localStorage.getItem(environment.commonproperty.localStorage.userData) || '{}') as XMYPROFILESummary;
    return 0;// userData ? +userData.PERSON_ID : 0;
  }

  getUserName(): string {
    return localStorage.getItem(environment.commonproperty.localStorage.userName) ||'';
  }

  getPhoto(): string {
    return localStorage.getItem(environment.commonproperty.localStorage.Photo) ||'';
  }

  getclientId(): string {
    return localStorage.getItem(environment.commonproperty.localStorage.clientId) ||'';
  }

  getclientname(): string {
    return localStorage.getItem(environment.commonproperty.localStorage.clientName) ||'';
  }

  getclientnameAr(): string {
    return localStorage.getItem(environment.commonproperty.localStorage.clientNameAr) ||'';
  }

  getemail(): string {
    return localStorage.getItem(environment.commonproperty.localStorage.email) ||'';
  }

  getcommercialRegistrationNumber(): string {
    return localStorage.getItem(environment.commonproperty.localStorage.commercialRegistrationNumber) ||'';
  }

  getMobile(): string {
    return localStorage.getItem(environment.commonproperty.localStorage.Mobile) ||'';
  }

  getNATIONALID(): string {
    return localStorage.getItem(environment.commonproperty.localStorage.NATIONALID) ||'';
  }

  getUserTypeId(): string {
    return localStorage.getItem(environment.commonproperty.localStorage.userTypeId) ||'';
  }

  getUserTypeName(): string {
    if (this.getLang() == 'ar') {
      return localStorage.getItem(environment.commonproperty.localStorage.userTypeNameAr) || '';
    } else {
      return localStorage.getItem(environment.commonproperty.localStorage.userTypeNameEn) || '';
    }
  }

  getIsLogout(): string {
    return localStorage.getItem(environment.commonproperty.localStorage.logout) ?? 'false';
  }

  saveLang(lang: string): void {
    localStorage.setItem(environment.commonproperty.localStorage.language, lang);
  }

  saveUserData(user: iUserModel): void {
    localStorage.setItem(environment.commonproperty.localStorage.userName, user.userName);
    // localStorage.setItem(environment.commonproperty.localStorage.Photo, user.photo);
    localStorage.setItem(environment.commonproperty.localStorage.clientId, user.clientId + '');
    localStorage.setItem(environment.commonproperty.localStorage.clientName, user.clientName);
    localStorage.setItem(environment.commonproperty.localStorage.clientNameAr, user.clientNameAr);
    localStorage.setItem(environment.commonproperty.localStorage.email, user.email);
    localStorage.setItem(environment.commonproperty.localStorage.userTypeId, user.userType?.Id + '');
    localStorage.setItem(environment.commonproperty.localStorage.commercialRegistrationNumber, user.commercialRegistrationNumber);
    localStorage.setItem(environment.commonproperty.localStorage.Mobile, user.mobile);
    localStorage.setItem(environment.commonproperty.localStorage.NATIONALID, user.nationalid);
    // localStorage.setItem(environment.commonproperty.localStorage.userTypeNameAr, user.userType?.NameAr);
    // localStorage.setItem(environment.commonproperty.localStorage.userTypeNameEn, user.userType?.NameEn);
  }

  updateUserData(user: iUserModel): void {
    localStorage.removeItem(environment.commonproperty.localStorage.userName);
    localStorage.setItem(environment.commonproperty.localStorage.userName, user.userName);

    localStorage.removeItem(environment.commonproperty.localStorage.clientName);
    localStorage.setItem(environment.commonproperty.localStorage.clientName, user.clientName);

    localStorage.removeItem(environment.commonproperty.localStorage.clientNameAr);
    localStorage.setItem(environment.commonproperty.localStorage.clientNameAr, user.clientNameAr);
    
    localStorage.removeItem(environment.commonproperty.localStorage.commercialRegistrationNumber);
    localStorage.setItem(environment.commonproperty.localStorage.commercialRegistrationNumber, user.commercialRegistrationNumber);
    
    localStorage.removeItem(environment.commonproperty.localStorage.Mobile);
    localStorage.setItem(environment.commonproperty.localStorage.Mobile, user.mobile);
    
    localStorage.removeItem(environment.commonproperty.localStorage.NATIONALID);
    localStorage.setItem(environment.commonproperty.localStorage.NATIONALID, user.nationalid);
  }

  saveIsLogout(): void {
    localStorage.setItem(environment.commonproperty.localStorage.logout, "true");
  }

  clearUserName(): void {
    localStorage.removeItem(environment.commonproperty.localStorage.userName);
  }

  clearLogOutStorageWithReturnLang(): string {
    const lang = this.getLang();

    localStorage.clear();
    sessionStorage.clear();
    localStorage.setItem(environment.commonproperty.localStorage.language, lang);

    return lang;
  }
}

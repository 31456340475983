export const commonEnv = {
  production: false,
  environmentName: "development",
  baseUrl:'https://feaah-api.technoapps.net/api/',
  files:'https://feaah-api.technoapps.net/files/',
  commonproperty: {
    renderFiles: {
      renderpublic: "files/renderpublic/",
      renderprivate: "files/renderprivate/",
    },
    localStorage: {
      language: 'language',
      userData: 'UserData',
      token: 'token',
      userName: 'userName',
      email: "email",
      expireOn: "ExpireOn",
      Photo: "Photo",
      IsVerified: "IsVerified",
      clientId: "ClientId",
      clientName: "ClientName",
      clientNameAr: "ClientNameAr",
      userTypeId: "UserTypeId",
      userTypeNameAr: "UserTypeNameAr",
      userTypeNameEn: "UserTypeNameEn",
      logout: "logout",
      lastUrl: "lasturl",
      servicepermission: 'ServicePermission',
      commercialRegistrationNumber: 'CommercialRegistrationNumber',
      Mobile: 'Mobile',
      NATIONALID: 'NATIONALID' 
    }
  },
  API: {
    Auth: {
      login: 'Authentication/LoginClient',
      signUp: 'Authentication/SignUpClient',
      changePassword: 'User/ChangePassword',
      forgetPassword: 'Authentication/ForgotPassword',
      resetpassword: 'Authentication/ResetPassword',
      refreshToken: 'Authentication/RefreshToken',
      logout: 'Authentication/Logout'
    },
    User: {
      Update: 'Client/UpdateClient',
      Disactive: 'Client/DisactiveClient',
      Active: 'Client/ActiveClient'
    },
    Dashboard: 'DashboardLocationClient/Get',
    GetNotification: 'DashboardLocationClient/getNotification',
    SeenNotification: 'SystemNotification/SeeNotification/',
    GetClientStatus: 'DashboardLocationClient/getClientStatus',
    location: {
      get: 'Location/Get',
      Create: 'Location/addLocation',
      Renew: 'Location/RenewLocationLicences',
      Update: 'Location/UpdateLocation',
      UpdateBaladyFiles: 'Location/updateLocationPerBalady',
      UpdateSalamaFiles: 'Location/updateLocationPerSalama',
      Details: 'LoadingDetailsAction/GetDetailsLocation?LocationId=',
      GetCities: 'LoadingDetailsAction/GetCity',
      PaidSalama: 'Location/UpdateLocationPaidSalama',
      PaidFinal: 'Location/UpdateLocationFinalComplete',
      UpdateSalamaLicenseForLocation: 'Location/UpdateSalamaLicenseForLocation',
    },
    FileUpload: {
      Upload: 'FileUpload',
      BaladyFileUpload: 'Location/BaladyFileUpload?LocationId=',
      SalamaFileUpload: 'Location/SalamaFileUpload?LocationId=',
      Download: 'FileUpload/DownloadFile'
    },
    balady: {
      GetDetails: 'LoadingDetailsAction/GetDetailsBalady'
    },
    salama: {
      GetDetails: 'LoadingDetailsAction/GetDetailsSalama'
    },
    // CMS
    VisionMission: {
      get : "VisionMission"
    },
    HistoryExperience : {
      get : "HistoryExperience"
    },
    TermCondition :{
      get : "TermCondition"
    },
    PricingFaq :{
      get : "PricingFaQ"
    },
    AboutUs :{
      get : "AboutUs"
    },
    PricingPackages :{
      get : "PricingPackages"
    },
    GuideToturial :{
      get : "GuideToturial"
    },
    ContactUs: {
      Create:"ContactUs"
    },
    Statistic: {
      get: "Statistic",
    },
    Feature: {
      get: "Feature",
    },
    Slider: {
      get:"Slider"
    },
    Partner:{
      get:"Partner"
    },
    ContactChannels: {
      get: "ContactChannels",
    },
    ///plan
    Card: {
      post:"Card",
      put:"Card",
      delete:"Card/RemoveToRecycleBin",
      getById:"Card",
      getByUserId:"Card/GetByUserId",
      getListByUserId:"Card/GetListByUserId"
    },
    Month:{
      getItemList:"Month/GetItemList"
    },
    Year:{
      getItemList:"Year/GetItemList"
    },
    Billing:{
      get:"Billing",
      getList:"Billing/GetList"
    },
    SystemPackage:{
      getList:"SystemPackage/GetList",
      getByUser:"SystemPackage/GetByUser",
      SubscribePackageClient: "SystemPackage/SubscribePackageClient"
    }
  }
};

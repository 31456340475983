import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { LocalStorageService } from '../local-storage/local-storage.service';
import { TokenStorageService } from '../token-storage/token-storage.service';

@Injectable({
  providedIn: 'root'
})
export class LogoutService {

  constructor(
    private localStorageService: LocalStorageService,
    private _tokenStorage: TokenStorageService) { }

  logOut(): void {
    this.localStorageService.saveIsLogout();

    const lang = this.localStorageService.clearLogOutStorageWithReturnLang();
    this._tokenStorage.clientLogout();

    window.location.href = "/user/login";
  }
}
